<template>
  <div v-if="loadingTable == true && data.length == 0">
    <b-skeleton type="button" width="80px" class="skeleton-button"></b-skeleton>
    <div class="skeleton-type">
      <b-skeleton animation="wave" width="200px" class="skeleton-title"></b-skeleton>
      <b-skeleton type="input"></b-skeleton>
    </div>
    <div class="skeleton-wrapper">
      <div class="skeleton-filter">
        <b-skeleton animation="wave" width="100px" class="skeleton-title"></b-skeleton>
        <b-skeleton type="input"></b-skeleton>
      </div>
      <div class="skeleton-date">
        <b-skeleton animation="wave" width="100px" class="skeleton-title"></b-skeleton>
        <b-skeleton type="input"></b-skeleton>
      </div>
    </div>
    <b-skeleton-table
      :rows="10"
      :columns="9"
      :table-props="{ bordered: true }"
    ></b-skeleton-table>
  </div>
  <div v-else>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3" v-if="$can('export')">
      <b-col sm class="d-flex justify-content-between">
        <div>
          <button class="btn btn-success m-2" type="button" @click="exportTable">Export</button>
        </div>
        <div>
          <button v-if="$can('partner_gold_sell_approve') && this.isApprove" class="btn btn-success m-2" type="button" @click="onAction('multiple')">Approve multiple</button>
          <button v-else-if="$can('partner_gold_sell_retry') && this.isRetry" class="btn btn-purple m-2" type="button" @click="onAction('retry-multiple')">Retry multiple</button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm="12">
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Name, Email, nomor invoice"
          >
            <b-input-group
            >
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                placeholder="Name, Email, nomor invoice ..."
                @keyup.enter="doFilter"
              >
              </b-form-input>
              <b-input-group-append
              >
                <b-button
                  variant="secondary" @click="resetFilter"
                  type="button"
                >
                  Reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Search By Status :"
            label-for="sp"
          >
            <b-form-select
              id="sp"
              v-model="type"
              @change="doFilterType"
              :options="[
                { text: 'Choose Status', value: '' },
                { text: 'Sedang Diproses', value: '0' },
                { text: 'Berhasil', value: '1' },
                { text: 'Ditolak', value: '2' },
                { text: 'Gagal', value: '3' }
              ]"
            >
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Date :"
            label-for="daterange"
          >
            <b-input-group
            >
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      :api-mode="false"
			:fields="fields"
      :per-page="limit"
			:data-manager="dataManager"
      pagination-path="pagination"
      @vuetable:pagination-data="onPaginationData">
        <template slot="checkbox-slot" slot-scope="prop">
          <div v-show="prop.rowData.can_approve || prop.rowData.can_retry">
            <input type="checkbox" @click="onChangeChecked(prop.rowData)" :value="prop.rowData" v-model="selected">
          </div>
        </template>
        <template slot="name-slot" slot-scope="prop">
          <router-link :to="onAction('view-user', prop.rowData, prop.rowIndex)" target="_blank">
            {{ `${prop.rowData.users.name} (${prop.rowData.users.email})` }}
          </router-link>
        </template>
        <template slot="partner-slot" slot-scope="prop">
          <span>{{ prop.rowData.partner_name || '-' }}</span>
        </template>
        <template slot="invoice-slot" slot-scope="prop">
          <span>{{ prop.rowData.trans_id || '-'}}</span>
        </template>
        <template slot="bank_name-slot" slot-scope="prop">
          <span>{{ prop.rowData.recipient || '-' }}</span>
        </template>
        <template slot="amount-slot" slot-scope="prop">
          <span>
            {{ Number(prop.rowData.amount) | currency }}
          </span>
        </template>
        <template slot="status-slot" slot-scope="prop">
          <span v-if="prop.rowData.status === 'Ditolak'" class="status-red" @click="onAction('modal-reason', prop.rowData)">{{ prop.rowData.status }}</span>
          <span v-else>{{ prop.rowData.status }}</span>
        </template>
        <template slot="actions-slot" slot-scope="prop">
          <div class="custom-actions">
            <button
              type="button"
              class="btn btn-warning mb-3 mx-3"
              @click="onAction('check-item', prop.rowData)">
              Check
            </button>
            <button
              type="button"
              class="btn btn-info mb-2"
              v-if="$can('partner_gold_sell_detail')"
              @click="onAction('detail-item', prop.rowData, prop.rowIndex)">
              Detail
            </button>
            <button
              type="button"
              class="btn btn-success mb-2"
              v-if="$can('partner_gold_sell_approve')"
              v-show="prop.rowData.can_approve"
              @click="onAction('approve', prop.rowData, prop.rowIndex)">
              Approve
            </button>
            <button
              type="button"
              class="btn btn-purple mb-2"
              v-if="$can('partner_gold_sell_retry')"
              v-show="prop.rowData.can_retry"
              @click="onAction('retry', prop.rowData, prop.rowIndex)">
              Retry
            </button>
            <button
              type="button"
              class="btn btn-pink mb-2"
              v-if="$can('partner_gold_sell_reject')"
              v-show="(prop.rowData.status === 'Sedang Diproses' && prop.rowData.listing === false && prop.rowData.is_send === false) || (prop.rowData.can_retry === true && (prop.rowData.status === 'Gagal' || prop.rowData.status === 'Sedang Diproses') && prop.rowData.listing === false)"
              @click="onAction('modal-reject', prop.rowData)">
              Reject
            </button>
            <button
              type="button"
              class="btn btn-danger mb-2"
              v-if="$can('partner_gold_sell_retry')"
              v-show="prop.rowData.can_retry === true && prop.rowData.status === 'Gagal'"
              @click="onAction('log-response', prop.rowData, logResponse = true)">
              Log
            </button>
          </div>
        </template>
      </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <div class="wrapper-pagination-custom">
        <div class="pagination-custom-info">
          <p>{{ this.paginationInfo }}</p>
        </div>
        <div class="pagination-custom-button">
          <button :disabled="current_page === 1" @click="doMovePage('first')">
            <i v-if="current_page === 1" class="fa fa-angle-double-left disabled"></i>
            <i v-else class="fa fa-angle-double-left"></i>
          </button>
          <button :disabled="current_page === 1" @click="doMovePage('prev')">
            <i v-if="current_page === 1" class="fa fa-angle-left disabled"></i>
            <i v-else class="fa fa-angle-left"></i>
          </button>
          <div>{{ this.current_page }}</div>
          <button :disabled="data.length < 10" @click="doMovePage('next')">
            <i v-if="data.length < 10" class="fa fa-angle-right disabled"></i>
            <i v-else class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
    <b-modal size="lg" title="Log Response" v-model="logResponse" @oke="logResponse = false">
      <div style="text-align: center; width: 100%">
        {{this.errorResponse}}
      </div>
    </b-modal>
    <b-modal size="md" v-model="modalReject" centered hide-header>
      <b-row class="mb-2 mt-3">
        <b-col sm="12" class="text-center">
          <h3 class="mb-4"><strong>Rejection Reason</strong></h3>
        </b-col>
      </b-row>
      <b-row class="mb-4 px-4">
        <b-col sm="12">
          <b-form-group
            class="mb-4"
            id="reasonGroup"
            label-for="reason"
          >
            <b-form-textarea
              id="reason"
              placeholder="Rejection Reason"
              aria-describedby="textFeedback"
              rows="4"
              no-resize
              v-model="reason"
            ></b-form-textarea>
          </b-form-group>
          <div class="text-center">
            <b-button class="mr-3" type="button" variant="outline-secondary" @click="onAction('modal-reject')">
              Cancel
            </b-button>
            <b-button v-if="this.reason.length > 0" type="button" variant="primary" @click="onAction('reject')">
              Submit
            </b-button>
            <b-button v-else variant="primary" disabled>
              Submit
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal size="md" v-model="modalReason" centered hide-header>
      <b-row class="mb-2 mt-3">
        <b-col sm="12" class="text-center">
          <h3 class="mb-4"><strong>Rejection Reason</strong></h3>
        </b-col>
      </b-row>
      <b-row class="mb-4 px-4">
        <b-col sm="12">
          <div class="mb-3 reason-wrapper">
            <p>{{ reason }}</p>
          </div>
          <div class="text-center">
            <b-button class="btn-outline" type="button" @click="onAction('modal-reason')">
              Close
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal title="Check Account" size="lg" v-model="modalCheck">
      <div v-if="this.isLoadingModal">
        <b-row class="mb-4">
          <b-col>
            <b-skeleton width="210px"></b-skeleton>
            <hr>
            <b-skeleton v-for="index in 5" :key="index" width="250px" style="margin: 0 0 10px 40px"></b-skeleton>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <b-skeleton width="180px"></b-skeleton>
            <hr>
            <b-skeleton v-for="index in 4" :key="index" width="230px" style="margin: 0 0 10px 40px"></b-skeleton>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <b-skeleton width="160px"></b-skeleton>
            <hr>
            <b-skeleton v-for="index in 3" :key="index" width="270px" style="margin: 0 0 10px 40px"></b-skeleton>
            <div style="margin-top: 32px">
              <b-skeleton width="500px"></b-skeleton>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="(typeof this.dataCheck.user !== 'undefined')">
        <b-row class="mb-4">
          <b-col>
            <h6>User Profile</h6>
            <hr>
            <ul style="font-size: 12pt">
              <li>
                Partner / Organization : {{ this.dataCheck.user.organization_name }}
              </li>
              <li>
                Nama : {{ this.dataCheck.user.name }}
              </li>
              <li>
                Email : {{ this.dataCheck.user.email }}
              </li>
              <li>
                No Telepon : {{ this.dataCheck.user.phone }}
              </li>
              <li>
                Alamat : {{ this.dataCheck.user.address }}
              </li>
            </ul>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <h5>
              Bank Account Details
            </h5>
            <hr>
            <ul style="font-size: 12pt">
              <li>
                Owner : {{ this.dataCheck.bank.account_name }}
              </li>
              <li>
                Account Number : {{ this.dataCheck.bank.account_number }}
              </li>
              <li>
                Bank Name : {{ this.dataCheck.bank.name }}
              </li>
              <li>
                Branch : {{ this.dataCheck.bank.branch }}
              </li>
            </ul>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <h6>Status Disbursement</h6>
            <hr>
            <ul style="font-size: 12pt">
              <li>
                Status Transaksi : {{ this.dataCheck.disbursement.status_text }}
              </li>
              <li>
                Response Message : {{ this.dataCheck.disbursement.message }}
              </li>
              <li>
                Tanggal Pengecekan : {{ this.dataCheck.disbursement.check_date }}
              </li>
            </ul>
            <div>
              <h5 :style="{ color: dataCheck.bank.status ? 'green' : 'red' }">{{ this.dataCheck.bank.message }}</h5>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import _ from "lodash"

  let startDate = '';
  let endDate = '';
  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return {
        page: 1,
        current_page: 1,
        limit: 10,
        type: '',
        data: [],
        dataCheck: [],
        startDate: '',
        endDate: '',
        selected: [],
        user: [],
        loadingTable: true,
        isApprove: false,
        isRetry: false,
        isLoading: false,
        isLoadingModal: false,
        modalReject: false,
        modalReason: false,
        modalCheck: false,
        filterText: '',
        logResponse: false,
        errorResponse: '',
        reason: '',
        reasonId: '',
        message: '',
        paginationInfo: '',
        dateDetail: {
          start_from: '',
          end_from: ''
        },
        errors: {
          code: '',
          message: '',
          status: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `partner-gold-sell/list`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sort: 'withdraw_sell.created_at|asc',
        sortOrder: [
          {
            field: 'created_at',
            sortField: 'created_at',
            direction: 'asc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'checkbox-slot',
            titleClass: 'center aligned',
            dataClass: 'center aligned',
            title: '',
            width: '5%'
          },
          {
            name: 'created_at',
            sortField: 'withdraw_sell.created_at',
            title: 'Request Date'
          },
          {
            name: 'name-slot',
            sortField: 'users.name',
            title: 'Nama Akun Profile'
          },
          {
            name: 'partner-slot',
            sortField: 'oauth_clients.name',
            title: 'Partner'
          },
          {
            name: 'invoice-slot',
            sortField: 'invoice.trans_id',
            title: 'Nomor Invoice'
          },
          {
            name: 'bank_name-slot',
            sortField: 'bank_accounts.account_number',
            title: 'Tujuan Pencairan Dana'
          },
          {
            name: 'amount-slot',
            sortField: 'withdraw_sell.amount',
            title: 'Nilai Rupiah'
          },
          {
            name: 'gold_amount',
            sortField: 'withdraw_sell.gold_amount',
            title: 'Jumlah Emas'
          },
          {
            name: 'status-slot',
            sortField: 'trans_id.status',
            title: 'Status'
          },
          {
            name: 'actions-slot',
            title: 'Actions',
            titleClass: 'center aligned',
            dataClass: 'center aligned'
          }
        ]
      }
    },
    watch: {
      // eslint-disable-next-line no-unused-vars
      data(newVal, oldVal) {
        this.$refs.vuetable.refresh();
      },
      // eslint-disable-next-line no-unused-vars
      modalReject(newVal, oldVal) {
        if (this.modalReject === false) this.reason = '';
      },
      // eslint-disable-next-line no-unused-vars
      selected(newVal, oldVal) {
        let checkRetry = [];
        let checkApprove = [];

        if (this.selected.length > 0) {
          this.selected.map((itm) => {
            // Check Approve condition
            if (itm.can_approve) {
              checkApprove.push(true)
            } else {
              checkApprove.push(false)
            }

            // Check Retry condition
            if (itm.can_retry) {
              checkRetry.push(true)
            } else {
              checkRetry.push(false)
            }
          })
        } else {
          checkRetry = []
          checkApprove = []
        }

        // Set true button Retry Multiple
        if ((checkRetry.length > 0) && !checkRetry.includes(false)) {
          this.isRetry = true;
        } else {
          this.isRetry = false;
        }

        // Set true button Approve Multiple
        if ((checkApprove.length > 0) && !checkApprove.includes(false)) {
          this.isApprove = true;
        } else {
          this.isApprove = false;
        }
      },
      modalCheck(newVal) {
        if (!newVal) this.user = [];
      }
    },
    methods: {
      updateFilter() {
        const historyFilter = localStorage.getItem('history_filter_goldSellPartner');
        const payload = {
          page: this.page,
          filterText: this.filterText,
          type: this.type,
          startDate: this.startDate,
          endDate: this.endDate,
          sortOrder: this.sortOrder,
          moreParams: this.moreParams
        };

        if (historyFilter && historyFilter !== JSON.stringify(payload)) {
          const newFilter = JSON.parse(historyFilter);
          this.page = newFilter.page;
          this.filterText = newFilter.filterText;
          this.type = newFilter.type;
          this.startDate = newFilter.startDate;
          this.endDate = newFilter.endDate;
          this.sortOrder = newFilter.sortOrder;
          this.moreParams = newFilter.moreParams;
          localStorage.removeItem('history_filter_goldSellPartner');
        }

        this.onFetch();
      },
      onFetch() {
        const newParams = Object.entries(this.moreParams).filter(([value]) => value !== '').map(([key, value]) => `${key}=${value}`).join('&');
        this.loadingTable = true;
        this.data = [];

        this.$http.get(`partner-gold-sell/list?sort=${encodeURIComponent(this.sort)}&page=${this.page}&per_page=${this.limit}&${newParams}`)
          .then((response) => {
            const startIndex = (response.data.current_page - 1) * this.limit;
            const endIndex = startIndex + this.limit;

            this.data = response.data.data;
            this.current_page = response.data.current_page;
            this.paginationInfo = response.data.data.length > 0 ? `Displaying ${startIndex + 1} to ${endIndex}` : 'No relevant data';

            if (this.loadingTable) this.loadingTable = false;
          })
          .catch((err) => {
            this.handleLoadError(err)
          })
      },
      onChangeChecked(data) {
        let index = this.selected.findIndex(itm => itm.id === data.id);

        if (index === -1) {
          this.selected.push(data);
        } else {
          this.selected.splice(index, 1);
        }
      },
      parseAmount(value) {
        return parseInt(value.replace(/[^\d]/g, ''))
      },
      doFilter () {
        this.$events.$emit('filter-set', this.filterText)
      },
      doFilterType () {
        this.$events.$emit('type-set', this.type)
      },
      doMovePage(value) {
        this.$events.$emit('movePage', value)
      },
      resetFilter () {
        this.filterText = ''  // clear the text in text input
        this.$events.$emit('filter-reset')
      },
      exportTable() {
        this.isLoading = true
        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = this.$setDate.format('YYYY-MM-DD');
        }
        this.$http.get(`partner-gold-sell/export?startDate=${startDate}&endDate=${endDate}&filter=${encodeURIComponent(this.filterText)}&status=${this.type}`)
        .then((result) => {
          this.isLoading =false
          const exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      resetDateFilter () {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      number(value){
        return value
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        console.log('paginationData', paginationData)
        // this.$refs.pagination.setPaginationData(paginationData)
        // this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
      },
      onAction (action, data) {
        if (action == 'view-user') {
          return { name: 'Detail End User', params: { id: btoa(data.users.id)} }
        }else if (action == 'detail-item') {
          const payload = {
            page: this.page,
            filterText: this.filterText,
            type: this.type,
            startDate: this.startDate,
            endDate: this.endDate,
            sortOrder: this.sortOrder,
            moreParams: this.moreParams
          }

          localStorage.setItem('history_filter_goldSellPartner', JSON.stringify(payload));
          this.$router.push({name: 'Detail Jual Emas Partner', params:{id: data.id, user_id: btoa(data.users.id)}})
        }else if (action === 'check-item') {
          this.modalCheck = true;
          this.isLoadingModal = true;
          this.$http.post(`partner-gold-sell/check-transaction`, {
            withdraw_id: data.id
          }).then((response) => {
            this.isLoadingModal = false;
            this.dataCheck = response.data.data;
          }).catch((error) =>{
            if (error.response) {
              this.modalCheck = false;
              this.isLoading = false
              this.isLoadingModal = false;
              this.errors.code = error.response.status;
              this.errors.message = error.response.data.meta.message;
              this.errors.status = error.response.data.meta.code;

              if (this.errors.status == 400 || this.errors.status == 404) {
                this.$swal.fire(
                  'Failed',
                  this.errors.message,
                  'error'
                ).then(() => {})
              }
            }
          })
        }else if (action === 'log-response') {
          if (this.isJsonString(data.response)) {
            this.errorResponse = JSON.parse(data.response)
          } else {
            this.errorResponse = data.response
          }
        }else if (action === 'modal-reject') {
          if (this.modalReject === true) {
            this.modalReject = false;
            this.reasonId = '';
          } else {
            this.modalReject = true;
            this.reasonId = data.id;
          }
        }else if (action === 'modal-reason') {
          if (this.modalReason === true) {
            this.modalReason = false;
            this.reason = '';
          } else {
            this.modalReason = true;
            this.reason = data.reason;
          }
        }else if (action === 'reject') {
          this.modalReject = false;
          this.isLoading = true;
          this.$http.post(`partner-gold-sell/reject`, {
            hashed_ids: [this.reasonId],
            reason: this.reason
          }).then(() => {
            this.isLoading = false
            this.$swal.fire(
              'Success!',
              'Rejected berhasil',
              'success'
            ).then(() => {
              location.reload()
            })
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.message = error.response.data.meta.message;
              this.errors.status = error.response.data.meta.code;
              if (this.errors.status  == 400) {
                this.$swal.fire(
                  'Failed',
                  this.errors.message,
                  'error'
                ).then(() => {
                  location.reload()
                })
              }else if (this.errors.status  == 404) {
                this.$swal.fire(
                  'Failed',
                  this.errors.message,
                  'error'
                ).then(() => {
                  location.reload()
                })
              }
            }
          })
        }else if (action === 'multiple') {
          this.$swal.fire({
          title: 'Apakah kamu yakin?',
          text: `Transaksi yang sudah di proses tidak dapat dibatalkan. Apakah anda yakin ingin memproses ${this.selected.length} transaksi tersebut ?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
              const userId = this.selected.map(itm => { return itm.id })
              this.isLoading = true
              this.$http.post(`partner-gold-sell/approve`, {
                hashed_ids: userId
              }).then(() => {
                this.isLoading = false
                this.$swal.fire(
                  'Success!',
                  'Approve berhasil',
                  'success'
                ).then(() => {
                  event.preventDefault();
                  this.$refs.vuetable.refresh();
                  this.selected = [];
                  this.onFetch();
                })
              }).catch((error) => {
                if (error.response) {
                  this.isLoading = false
                  this.errors.code = error.response.status;
                  this.errors.message = error.response.data.meta.message;
                  this.errors.status = error.response.data.meta.code;
                  if (this.errors.status  == 400) {
                    this.$swal.fire(
                      'Failed',
                      this.errors.message,
                      'error'
                    ).then(() => {
                      event.preventDefault();
                      this.$refs.vuetable.refresh();
                      this.selected = [];
                      this.onFetch();
                    })
                  }else if (this.errors.status  == 404) {
                    this.$swal.fire(
                      'Failed',
                      this.errors.message,
                      'error'
                    ).then(() => {
                      location.reload();
                    })
                  }
                }
              })
            }
          })
        }else if (action === 'retry-multiple') {
          this.$swal.fire({
          title: 'Apakah kamu yakin?',
          text: `Transaksi yang sudah di proses tidak dapat dibatalkan. Apakah anda yakin ingin memproses ${this.selected.length} transaksi tersebut ?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
              const userId = this.selected.map(itm => { return itm.id })
              this.isLoading = true
              this.$http.post(`partner-gold-sell/retry-approve`, {
                hashed_ids: userId
              }).then(() => {
                this.isLoading = false
                this.$swal.fire(
                  'Success!',
                  'Approve berhasil',
                  'success'
                ).then(() => {
                  event.preventDefault();
                  this.$refs.vuetable.refresh();
                  this.selected = [];
                  this.onFetch();
                })
              }).catch((error) => {
                if (error.response) {
                  this.isLoading = false
                  this.errors.code = error.response.status;
                  this.errors.message = error.response.data.meta.message;
                  this.errors.status = error.response.data.meta.code;
                  if (this.errors.status  == 400) {
                    this.$swal.fire(
                      'Failed',
                      this.errors.message,
                      'error'
                    ).then(() => {
                      event.preventDefault();
                      this.$refs.vuetable.refresh();
                      this.selected = [];
                      this.onFetch();
                    })
                  }else if (this.errors.status  == 404) {
                    this.$swal.fire(
                      'Failed',
                      this.errors.message,
                      'error'
                    ).then(() => {
                      location.reload();
                    })
                  }
                }
              })
            }
          })
        }else {
          this.isLoading = true
          this.$http.post(`partner-gold-sell/${action == 'approve' ? 'approve' : 'retry-approve'}`, {
            hashed_ids: [data.id]
          }).then(() => {
            this.isLoading = false
            this.$swal.fire(
              'Success!',
              'Approve berhasil',
              'success'
            ).then(() => {
              event.preventDefault();
              this.$refs.vuetable.refresh();
              this.selected = [];
              this.onFetch();
            })
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.message = error.response.data.meta.message;
              this.errors.status = error.response.data.meta.code;
              if (this.errors.status  == 400) {
                this.$swal.fire(
                  'Failed',
                  this.errors.message,
                  'error'
                ).then(() => {
                  event.preventDefault();
                  this.$refs.vuetable.refresh();
                  this.selected = [];
                  this.onFetch();
                })
              }else if (this.errors.status  == 404) {
                this.$swal.fire(
                  'Failed',
                  this.errors.message,
                  'error'
                ).then(() => {
                  location.reload();
                })
              }
            }
          })
        }
      },
      onFilterSet (value) {
        const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'filter'));
        this.filterText = value;
        this.page = 1;
        this.moreParams = {
          ...newParams,
          'filter': encodeURIComponent(value)
        };
        this.onFetch();
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate]);
      },
      onDateSet()  {
        const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => (key !== 'tart_from') && (key !== 'end_to')));
        this.page = 1;
        this.moreParams = {
          ...newParams,
          'start_from': this.startDate,
          'end_to': this.endDate
        };
        this.onFetch();
      },
      onFilterReset () {
        this.page = 1;
        this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'filter'));
        this.onFetch();
      },
      onDateFilterReset () {
        this.page = 1
        this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => (key !== 'start_from') && (key !== 'end_to')))
        this.onFetch()
      },
      onTypeFilterSet () {
        if (this.type) {
          const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'status'))
          this.page = 1
          this.moreParams = {
            ...newParams,
            'status' : this.type
          }
          this.onFetch()
        } else {
          this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'status'))
          this.onFetch()
        }
      },
      dataManager(sortOrder, pagination) {
        let local = this.data;

        if (sortOrder.length > 0) {
          const newSort = `${sortOrder[0].sortField}|${sortOrder[0].direction}`;

          if (this.sort !== newSort) {
            this.sort = newSort;
            this.onFetch();
          }
        }

        pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.limit
        );

        let from = pagination.from - 1;
        let to = from + this.limit;

        return {
          pagination: pagination,
          data: local.length > 0 ? _.slice(local, from, to) : []
        };
      },
      onMovePage(eventData) {
        if (eventData === 'first') {
          this.page = 1;
          this.onFetch();
        } else if (eventData === 'prev') {
          this.page--;
          this.onFetch();
        } else {
          this.page++;
          this.onFetch();
        }
      }
    },
    mounted() {
      this.updateFilter();
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
      this.$events.$on('type-set', () => this.onTypeFilterSet())
      this.$events.$on('movePage', eventData => this.onMovePage(eventData))
    }
  }
</script>
<style>
.reason-wrapper {
  width: 100%;
  min-height: 60px;
  text-align: center;
}

.custom-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.status-red {
  color: #CB211F;
  text-decoration: underline;
  cursor: pointer;
}

.form-rejection {
  width: '300px';
  background-color: #E77471;
}
.btn-purple {
  color: #fff;
  background-color: #4933F3;
  border-color: #4933F3;
}
.btn-pink {
  color: #fff;
  background-color: #E77471;
  border-color: #E77471;
}
.btn-outline {
  width: 80px;
  padding: 8px 12px;
  border: 1px solid #DDDDDD;
  color: #606060;
  background-color: transparent;
}
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

footer.modal-footer {
    display: none;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

/* Pagination Custom */
.wrapper-pagination-custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
}

.pagination-custom-button {
  display: flex;
}

.pagination-custom-button button {
  width: 28px;
  height: 47px;
  padding: 13px 16px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: solid rgba(0,0,0,0.3);
}

.pagination-custom-button button:first-child {
  padding: 13px 16px 13px 30px;
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px
}

.pagination-custom-button button:nth-child(2),
.pagination-custom-button button:last-child {
  font-size: 1.3em;
  border-width: 1px;
}

.pagination-custom-button button:last-child {
  border-radius: 0 4px 4px 0;
}

.pagination-custom-button button i.disabled {
  opacity: 0.6;
}

.pagination-custom-button div {
  width: 28px;
  height: 47px;
  font-size: 1em;
  font-weight: 500;
  background: #FFFFFF;
  border: solid rgba(0,0,0,0.3);
  border-width: 1px 0 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Skeleton */
.skeleton-button,
.skeleton-type,
.skeleton-wrapper {
  margin-bottom: 20px;
}

.skeleton-title {
  height: 18px;
  margin-bottom: 10px;
}

.skeleton-filter {
  margin-right: 30px;
}

.skeleton-wrapper {
  display: grid;
  grid-template-columns: auto auto;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
